a {
  text-decoration: none !important;
}

.btn-remaster-wrapper-heroimg {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: start;
  cursor: pointer;
  border: 2px solid;
  border-radius: 25px;
  padding: 15px 10px;
  gap: 10px;

  border: 1px black solid;
}

.btn-remaster-wrapper-heroimg:hover {
  box-shadow: 2px 2px 5px 2px rgba(255, 255, 255, 0.5) !important;
}

.btn-remaster-text {
  font-weight: 800;
  line-height: 20px;
  font-size: 18px;
}

.arrow-style {
  font-size: 5px;
  font-weight: bold;
}

@media (min-width: 778px) {
  .btn-remaster-wrapper-heroimg {
    text-align: center;
  }

  .btn-remaster-text {
    font-size: 32px;
    padding: 8px 24px;
  }
}
